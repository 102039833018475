import { Divider, message} from "antd";
import React, { useEffect } from "react";
import { StripeIntegration } from "Components/Integration/dataSource/StripeIntegration";
import { SlackIntegration } from "Components/Integration/SlackIntegration";
import { DataSourceIntegration } from "Components/Integration/dataSource/DataSourceIntegration";
import styled from 'styled-components';

const SectionDivider = styled(Divider)`
    margin: 40px 0;
    background-color: #f0f0f0;
    height: 2px;
`;

const IntegrationSection = styled.div`
    background: #fff;
    padding: 24px;
    border-radius: 8px;
    border: 1px solid #f0f0f0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03);
`;

const Integration = () => {

    // Show messages if integration is successful.
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const status = searchParams.get('status');
        const res_message = searchParams.get('message');

        if (status && status === 'error') {
            message.error(res_message || "Something went wrong");
        }
        if (status && status === 'warning-message') {
            message.warning("Message Destination is already connected.")
        }
        if (status && status === 'warning-source') {
            message.warning("Data Source is already connected.")
        }
        // Clear the URL parameters after showing the message
        if (status) {
            window.history.replaceState({}, '', '/integration');
        }
    }, [])

    return (
        <div className='integration'>
            <h2 style={{marginBottom: "30px"}}> Integrations </h2>

            <IntegrationSection>
                <DataSourceIntegration />
            </IntegrationSection>
            
            <SectionDivider />
            
            <IntegrationSection>
                <SlackIntegration />
            </IntegrationSection>
            
        </div>
    )
}

export default Integration;