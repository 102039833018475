import { Button, message, List, Spin, Popconfirm, Avatar } from "antd";
import React, { useEffect, useState } from "react";
import axios from "utils/axios";
import { useAuth } from "hooks/useAuth";
import { NavLink } from "react-router-dom";
import { StripeIntegration } from "./StripeIntegration";
import { ShopifyPartnerIntegration } from "./ShopifyPartnerIntegration"

const DeleteIntegrationButton = ({ item, fetchDataSources }) => {

    const [loading, setLoading] = useState(false);

    const onDeleteStripe = async () => {
        setLoading(true);
        try {
            await axios.get(`source/stripe/deauthorize?id=${item.id}`);
            fetchDataSources();
            message.success("Data source deleted");
        } catch (error) {
            message.error(
                (error &&
                    error.response &&
                    error.response.data &&
                    error.response.data.detail) ||
                "Something went wrong"
            );
        } finally {
            setLoading(false);
        }
    };

    const onDeleteShopify = async () => {
        setLoading(true);
        try {
            await axios.get(`source/shopifypartner/delete?id=${item.id}`);
            fetchDataSources();
            message.success("Data source deleted");
        } catch (error) {
            message.error(
                (error &&
                    error.response &&
                    error.response.data &&
                    error.response.data.detail) ||
                "Something went wrong"
            );
        } finally {
            setLoading(false);
        }
    };
    
    const onDelete = () => {
        if (item.gateway_name === 'stripe') {
            onDeleteStripe();
        } else if (item.gateway_name === 'shopify_partner') {
            onDeleteShopify();
        }
    };

    return (
        <Popconfirm
            title="Confirm"
            description="Are you sure you want to remove this integration?"
            okText="Yes, Remove"
            onConfirm={onDelete}
            okButtonProps={{
                loading: loading,
            }}
        >
            <a style={{ fontSize: '14px', color: '#666' }}>X</a>
        </Popconfirm>
    );
}

const DataSourceIntegration = () => {

    const [dataSources, setDataSources] = useState(null);
    const redirectUri = `${process.env.REACT_APP_BASE_URL}/source/stripe/callback`
    
    const { currentUser, isSubscribed } = useAuth();

    const accountId = currentUser.id
    const stripeAuthoriseUrl = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_STRIPE_CLIENT_ID}&scope=read_write&redirect_uri=${redirectUri}&state=${accountId}`
   

    const fetchDataSources = () => {
        axios
            .get("source/")
            .then((res) => setDataSources(res.data))
            .catch(error => console.log(error))
    };

    useEffect(() => {
        fetchDataSources()
    }, [])

    const isIntegrationValid = () => {
        // Check if the integration is valid according to constraints.
        if (dataSources && !isSubscribed && dataSources.length >= 1) {
            message.info("Free plan includes 1 data source. Upgrade to a paid plan connect more.");
            return false
        }

        return true
    }

    const connectStripe = () => {
        if (dataSources && !isSubscribed && dataSources.length >= 1) {
            message.info("Free plan includes 1 data source. Upgrade to a paid plan connect more.");
        }
        else {
            window.location.href = stripeAuthoriseUrl;
        }
    }

    const getDataSourceImage = (gatewayName) => {
        if (gatewayName === 'stripe') {
            return '/static/stripe.png';
        } else if (gatewayName === 'shopify_partner') {
            return '/static/shopify.png';
        }
        // Default image if gatewayName doesn't match any condition
        return '/static/default.png';
    };

    return (
        <div class="message-source">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                <p style={{ margin: 0 }}><b>Connected Data Sources</b></p>
                <div style={{ display: 'flex', gap: '8px' }}>
                    <StripeIntegration fetchDataSources={fetchDataSources} isIntegrationValid={isIntegrationValid} />
                    <ShopifyPartnerIntegration fetchDataSources={fetchDataSources} isIntegrationValid={isIntegrationValid} />
                </div>
            </div>

            {dataSources ? (
                dataSources.length > 0 ? (
                    <List className="mb-1"
                        itemLayout="horizontal"
                        dataSource={dataSources}
                        renderItem={(item, index) => (
                            <List.Item
                                actions={[<DeleteIntegrationButton item={item} fetchDataSources={fetchDataSources} />]}
                            >
                                <List.Item.Meta
                                    title={item.name}
                                    avatar={<Avatar size="small" shape="square" src={getDataSourceImage(item.gateway_name)} />}
                                />
                            </List.Item>
                        )}
                    />
                ) : (
                    <p style={{ color: "#828282" }}>Please connect your first data source.</p>
                )
            ) : (
                <p><Spin size="small" /></p>
            )}

            {dataSources && !isSubscribed && dataSources.length >= 1 &&
                <p>
                    To connect more data sources, <NavLink to="/billing"><u>upgrade to a paid plan</u></NavLink>.
                </p>
            }
        </div>)
}


export { DataSourceIntegration };