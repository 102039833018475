/**
 * @fileoverview StatsBotForm Component
 * 
 * This is the main form component for creating and editing StatsBots. It handles the configuration
 * of automated messages that will be sent to Slack channels with metrics from different data sources
 * (Stripe, Shopify Partner).
 * 
 * Key Features:
 * - Integration with Slack for message destinations
 * - Support for multiple data sources (Stripe, Shopify Partner)
 * - Variable insertion in messages
 * - Scheduling options (daily, weekly, monthly)
 * - Timezone selection
 * - Test message functionality
 * 
 * Component Structure:
 * - Uses Ant Design Form components
 * - Integrates with StyledMessageInput for message composition
 * - Shows metrics variables based on selected data source
 * 
 * Data Flow:
 * 1. Loads initial data (Slack accounts, data sources)
 * 2. Updates available channels when message destination changes
 * 3. Updates available variables when data source changes
 * 4. Manages form state and validation
 * 
 * @example
 * // Basic usage
 * <StatsBotForm
 *   onSubmit={handleSubmit}
 * />
 * 
 * @example
 * // Edit mode with initial values
 * <StatsBotForm
 *   isEdit={true}
 *   initialValues={existingStatsBot}
 *   onSubmit={handleUpdate}
 * />
 */

import React, { useState, useEffect } from "react";
import { Form, Input, Select, Button, Spin, message, Modal } from 'antd';
import axios from "utils/axios";
import { timezones } from "utils"
import { usePrompt } from "hooks/usePrompt";
import { defaultStripeMessage, defaultShopifyPartnerMessage } from "utils";
import { StripeMetricGrid, ShopifyPartnerMetricGrid } from "./metricGrid";
import { stripeVariables } from '../../utils/variables';
import { shopifyPartnerVariables } from '../../utils/variables';
import VariableSelector from '../VariableSelector/VariableSelector';
import styled from 'styled-components';
import StyledMessageInput from '../StyledMessageInput/StyledMessageInput';

const { Option } = Select;

const MessageInput = styled(Input.TextArea)`
  margin-bottom: 16px;
`;

const HighlightedMessage = styled.div`
  font-family: inherit;
  white-space: pre-wrap;
  word-break: break-word;
  padding: 4px 11px;
  min-height: 100px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  background-color: #fff;
  
  &:hover {
    border-color: #4096ff;
  }
`;

const Variable = styled.span`
  background-color: #f5f5f5;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  padding: 2px 6px;
  margin: 0 2px;
  font-family: monospace;
  cursor: pointer;
  
  &:hover {
    background-color: #e8e8e8;
  }
`;

const StyledTextArea = styled(Input.TextArea)`
  .variable {
    background-color: #f5f5f5;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    padding: 2px 6px;
    margin: 0 2px;
    font-family: monospace;
  }
`;

const VariableSelectorContainer = styled.div`
  margin-bottom: 16px;
`;

const VariableHelp = styled.div`
  margin-bottom: 8px;
  color: #666;
`;

/**
 * StatsBotForm Component
 * 
 * Main form component for StatsBot configuration. Handles all aspects of creating
 * and editing StatsBots including message composition, scheduling, and data source selection.
 * 
 * @component
 * @param {Object} props - Component props
 * @param {boolean} [props.isEdit=false] - Whether the form is in edit mode
 * @param {Object} [props.initialValues={}] - Initial values for form fields
 * @param {function} props.onSubmit - Callback when form is submitted
 * 
 * State Management:
 * - Form state managed by Ant Design Form
 * - Additional state for dynamic data (Slack channels, data sources)
 * - Message state synchronized between form and StyledMessageInput
 * 
 * Data Loading:
 * - Loads Slack accounts on mount
 * - Loads data sources on mount
 * - Loads Slack channels when message destination changes
 * 
 * Related Components:
 * - StyledMessageInput: For message composition
 * - StripeMetricGrid/ShopifyPartnerMetricGrid: For displaying available variables
 * 
 * @example
 * // Inside a parent component
 * const handleSubmit = async (values) => {
 *   try {
 *     await createStatsBot(values);
 *     message.success('StatsBot created successfully');
 *   } catch (error) {
 *     message.error('Failed to create StatsBot');
 *   }
 * };
 * 
 * return (
 *   <StatsBotForm
 *     onSubmit={handleSubmit}
 *   />
 * );
 */
const StatsBotForm = ({ isEdit = false, initialValues = {}, onSubmit }) => {

    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);

    const [slackAccounts, setSlackAccounts] = useState([]);
    const [stripeAccounts, setStripeAccounts] = useState([]);
    const [slackChannels, setSlackChannels] = useState([]);
    const [defaultSlackAccount, setDefaultSlackAccount] = useState(null);
    const [defaultStripeAccount, setDefaultStripeAccount] = useState(null);
    const [dataSources, setDataSources] = useState(null);
    const [defaultDataSource, setDeafultDataSource] = useState(null);

    const [defaultMessage, setDefaultMessage] = useState(null)

    // store the current value of datasource (stripe or shopifypartner)
    const [currentDataSourceCategory, setCurrentDataSourceCategory] = useState(null)

    // To fetch the slack channels when the initialvalues are loaded the first time.
    const [initialChannelsLoaded, setInititalChannelsLoaded] = useState(false);

    const [showPostDay, setShowPostDay] = useState(false);
    const [showPostDate, setShowPostDate] = useState(false);

    const [showDialog, setShowDialog] = useState(false);

    const [formData, setFormData] = useState({
        message: isEdit ? initialValues?.message : defaultMessage,
        // ... other form fields ...
    });

    // Add state for modal
    const [previewVisible, setPreviewVisible] = useState(false);

    /**
     * Prompt user when leaving with unsaved changes
     */
    usePrompt(
        `Configuration of this Stats Bot has not been saved. If you leave the page, this configuration will be lost. \n
Are you sure you want to leave this page?`,
        showDialog
    );

    /**
     * Handle form submission
     * Disables the form during submission and handles errors
     * 
     * @param {Object} values - Form values to be submitted
     */
    const handleSubmit = (values) => {
        setIsLoading(true);
        setShowDialog(false);
        onSubmit(values)
            .catch(error => console.log(error))
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        // Fetch message destinations
        axios
            .get('message/slack')
            .then(res => {
                setSlackAccounts(res.data);
                setDefaultSlackAccount(res.data[0]);
            })
            .catch(error => console.error(error));

        // Fetch data sources
        axios
            .get("source/")
            .then((res) => {
                setDataSources(res.data);
                setDeafultDataSource(res.data[0]);
                
                // set the default message for stripe and slack accordingly when the page loads
                setDefaultMessageFunction(res.data[0].gateway_name)
                setCurrentDataSourceCategory(res.data[0].gateway_name)  
            })
            .catch(error => console.log(error))

    }, []);

    // Fetch all channels for the initial slack account.
    useEffect(() => {

        // set the metric grid according to the initial data_source
        if (isEdit && initialValues && initialValues.data_source) {
            setCurrentDataSourceCategory(initialValues.data_source.gateway_name)
        }

        if (initialValues && initialValues?.message_destination && !initialChannelsLoaded)
            axios
                .get(`message/slack/channels/${initialValues.message_destination.id}`)
                .then(res => {
                    setSlackChannels(res.data.channels)
                    setInititalChannelsLoaded(true)
                })
                .catch(error => console.error(error));

        if (initialValues && initialValues?.post_frequency) {
            if (initialValues?.post_frequency === 'Weekly') {
                setShowPostDay(true);
                setShowPostDate(false);
            } else if (initialValues?.post_frequency === 'Monthly') {
                setShowPostDay(false);
                setShowPostDate(true);
            }
        }
    }, [initialValues])

    /**
     * Handle message destination (Slack account) change
     * Fetches available channels for the selected Slack account
     * 
     * @param {string} value - Selected Slack account ID
     */
    const handleDestinationChange = (value) => {
        // Fetch message channel ID and set channel id to NULL
        form.setFieldsValue({ message_channel_id: null });
        setSlackChannels([]);
        axios
            .get(`message/slack/channels/${value}`)
            .then(res => {
                setSlackChannels(res.data.channels);
            })
            .catch(error => {
                console.error('Error fetching Slack channels:', error);
                message.error('Failed to fetch Slack channels');
            });
    };

    /**
     * Handle post frequency change
     * Shows/hides relevant date/day selection fields based on frequency
     * 
     * @param {string} value - Selected frequency (Daily, Weekly, Monthly)
     */
    const handlePostFrequencyChange = (value) => {
        if (value === 'Weekly') {
            setShowPostDay(true);
            setShowPostDate(false);
        } else if (value === 'Monthly') {
            setShowPostDay(false);
            setShowPostDate(true);
        } else {
            setShowPostDay(false);
            setShowPostDate(false);
        }
    };

    /**
     * Get formatted gateway name for display
     * 
     * @param {string} name - Gateway name from API
     * @returns {string} Formatted gateway name
     */
    const getGatewayName = (name) => {
        if (name === 'stripe')
            return "Stripe";
        else if (name === "shopify_partner")
            return "Shopify Partner";
    };

    /**
     * Set default message based on data source
     * 
     * @param {string} name - Data source name
     */
    const setDefaultMessageFunction = (name) => {
        if (name === 'stripe') {
            setDefaultMessage(defaultStripeMessage);
        }
        else if (name === 'shopify_partner') {
            setDefaultMessage(defaultShopifyPartnerMessage);
        }
    };

    /**
     * Initialize form data with default message based on data source
     */
    useEffect(() => {
        if (!isEdit && currentDataSourceCategory) {
            const defaultTemplate = currentDataSourceCategory === 'stripe' 
                ? defaultStripeMessage 
                : defaultShopifyPartnerMessage;
            
            setFormData(prev => ({
                ...prev,
                message: defaultTemplate
            }));
            
            // Also update the form field
            form.setFieldValue('message', defaultTemplate);
        }
    }, [currentDataSourceCategory, isEdit]);

    /**
     * Handle data source change
     * Updates the current data source category and default message
     * 
     * @param {string} value - Selected data source ID
     */
    const ondataSourceChange = (value) => {
        axios
            .get(`source/${value}`)
            .then((res) => {
                const gatewayName = res.data.gateway_name;
                setCurrentDataSourceCategory(gatewayName);
                
                // Set default message based on data source type
                const defaultTemplate = gatewayName === 'stripe' 
                    ? defaultStripeMessage 
                    : defaultShopifyPartnerMessage;
                
                form.setFieldValue('message', defaultTemplate);
                setFormData(prev => ({
                    ...prev,
                    message: defaultTemplate
                }));
            })
            .catch(error => {
                console.error('Error fetching data source:', error);
                message.error('Failed to fetch data source details');
            });
    };

    /**
     * Handle variable selection
     * Inserts the selected variable at the current cursor position
     * 
     * @param {string} variable - Selected variable to insert
     */
    const handleVariableSelect = (variable) => {
        const textArea = document.getElementById('message');
        const start = textArea.selectionStart;
        const end = textArea.selectionEnd;
        const text = formData.message || '';
        const newText = text.substring(0, start) + variable + text.substring(end);
        
        // Update both form data and form field
        setFormData(prev => ({ ...prev, message: newText }));
        form.setFieldValue('message', newText);
        
        // Set cursor position after the inserted variable and ensure it's visible
        setTimeout(() => {
            textArea.focus();
            textArea.setSelectionRange(start + variable.length, start + variable.length);
            
            // Calculate the line height and current cursor position
            const lineHeight = parseInt(window.getComputedStyle(textArea).lineHeight);
            const cursorPosition = textArea.selectionStart;
            const lines = textArea.value.substr(0, cursorPosition).split('\n');
            const currentLine = lines.length;
            
            // Scroll to make the cursor visible with some padding
            const scrollPosition = (currentLine * lineHeight) - (textArea.clientHeight / 2);
            textArea.scrollTop = Math.max(0, scrollPosition);
        }, 0);
    };

    /**
     * Get variables based on data source type
     * Returns the appropriate set of variables for the current data source
     * 
     * @param {string} dataSourceType - Current data source type
     * @returns {Object} Variables object for the data source
     */
    const getVariablesForDataSource = (dataSourceType) => {
        switch(dataSourceType) {
            case 'stripe':
                return stripeVariables;
            case 'shopify_partner':
                return shopifyPartnerVariables;
            default:
                return null;
        }
    };

    /**
     * Send test message to Slack
     * Sends the current message to the selected Slack channel
     */
    const sendTestMessageToSlack = () => {
        // Validate all required fields using form validation
        form.validateFields(['message_destination', 'message_channel_id', 'data_source', 'message'])
            .then(values => {
                const data = {
                    message: values.message,
                    message_destination_id: values.message_destination,
                    message_channel_id: values.message_channel_id,
                    data_source_id: values.data_source
                }

                message.info('Message may take a few minutes if there is a lot of data to process.')
                axios
                    .post(`bot/sentmessage/slack`, data)
                    .then(() => {
                        message.success('Message sent successfully!');
                    })
                    .catch(error => {
                        const errorData = error.response?.data;
                        
                        // Handle specific error types
                        switch(errorData?.error_type) {
                            case 'data_source_deleted':
                                message.error('The data source integration has been deleted. Please reconfigure the statsbot with a valid data source.');
                                break;
                            case 'stripe_failed':
                                message.error('Failed to fetch data from Stripe. The integration may have expired or been revoked.');
                                break;
                            case 'shopify_failed':
                                message.error('Failed to fetch data from Shopify Partner. The integration may have expired or been revoked.');
                                break;
                            case 'slack_failed':
                                message.error('Failed to send message to Slack. The integration may have expired or been revoked.');
                                break;
                            case 'slack_error':
                                message.error(errorData.message || 'Failed to send message to Slack.');
                                break;
                            default:
                                message.error(errorData?.message || 'Something went wrong.');
                        }
                        console.error(error);
                    });
            })
            .catch(({ errorFields }) => {
                const missingFields = errorFields.map(field => field.name[0]);
                if (missingFields.length > 0) {
                    message.error(`Please fill in these required fields: ${missingFields.join(', ')}`);
                }
            });
    };


    // Create a set of initial values if creating statsBot
    if (!isEdit) {
        initialValues = {
            'name': '',
            'message_destination': defaultSlackAccount,
            'data_source': defaultDataSource,
            'post_time': '12:30:00',
            'post_frequency': 'Daily',
            'timezone': 'UTC',
        }
    }

    const daysArray = Array.from({ length: 28 }, (_, index) => index + 1);

    const renderMessageWithHighlightedVariables = (message) => {
        if (!message) return '';
        
        const parts = message.split(/(\{[^}]+\})/g);
        return parts.map((part, index) => {
            if (part.match(/^\{[^}]+\}$/)) {
                return <Variable key={index}>{part}</Variable>;
            }
            return part;
        });
    };

    const renderPreviewMessage = () => {
        if (!formData.message) return '';
        let previewText = formData.message;

        // Replace all variables with dummy data
        const allVariables = previewText.match(/\{[^}]+\}/g) || [];
        allVariables.forEach(variable => {
            let replacement = '';
            if (variable.includes('appname')) {
                replacement = 'DelightChat';
            } else if (variable.includes('date')) {
                const today = new Date();
                replacement = today.toLocaleDateString('en-GB', { 
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric'
                }).replace(',', '');
            } else if (variable.includes('activesubscriptions')) {
                replacement = '32';
            } else if (variable.includes('activemonthlysubscriptions')) {
                replacement = '15';
            } else if (variable.includes('activequarterlysubscriptions')) {
                replacement = '8';
            } else if (variable.includes('activesemiannualsubscriptions')) {
                replacement = '4';
            } else if (variable.includes('activeyearlysubscriptions')) {
                replacement = '5';
            } else if (variable.includes('forecastmonthendmrr')) {
                replacement = '$10,450';
            } else if (variable.includes('forecastyearendrevenue')) {
                replacement = '$124,920';
            } else if (variable.includes('mrr')) {
                replacement = '$10,410';
            } else if (variable.includes('arr')) {
                replacement = '$124,880';
            } else if (variable.includes('revenue')) {
                if (variable.includes('365d')) {
                    replacement = '$2,035,420 → $3,999,800 (🟢 +96%)';
                } else if (variable.includes('28d')) {
                    replacement = '$99,980 → $120,000 (🟢 +20%)';
                } else if (variable.includes('7d')) {
                    replacement = '$25,000 → $22,500 (🔴 -10%)';
                } else if (variable.includes('1d')) {
                    replacement = '$3,500 → $4,200 (🟢 +20%)';
                }
            } else if (variable.includes('subscriptionrevenue')) {
                if (variable.includes('365d')) {
                    replacement = '$1,835,420 → $3,599,800 (🟢 +96%)';
                } else if (variable.includes('28d')) {
                    replacement = '$89,980 → $110,000 (🟢 +22%)';
                } else if (variable.includes('7d')) {
                    replacement = '$22,000 → $20,500 (🔴 -7%)';
                } else if (variable.includes('1d')) {
                    replacement = '$3,200 → $3,800 (🟢 +19%)';
                }
            } else if (variable.includes('usagerevenue')) {
                if (variable.includes('365d')) {
                    replacement = '$200,000 → $400,000 (🟢 +100%)';
                } else if (variable.includes('28d')) {
                    replacement = '$10,000 → $10,000 (0%)';
                } else if (variable.includes('7d')) {
                    replacement = '$3,000 → $2,000 (🔴 -33%)';
                } else if (variable.includes('1d')) {
                    replacement = '$300 → $400 (🟢 +33%)';
                }
            } else if (variable.includes('payingstorescount') || variable.includes('payments')) {
                if (variable.includes('365d')) {
                    replacement = '97 → 142 (🟢 +46%)';
                } else if (variable.includes('28d')) {
                    replacement = '12 → 15 (🟢 +25%)';
                } else if (variable.includes('7d')) {
                    replacement = '3 → 4 (🟢 +33%)';
                } else if (variable.includes('1d')) {
                    replacement = '1 → 2 (🟢 +100%)';
                }
            } else if (variable.includes('arpu')) {
                if (variable.includes('365d')) {
                    replacement = '$20.98 → $28.17 (🟢 +34%)';
                } else if (variable.includes('28d')) {
                    replacement = '$8,332 → $8,000 (🔴 -4%)';
                } else if (variable.includes('7d')) {
                    replacement = '$8,333 → $5,625 (🔴 -32%)';
                } else if (variable.includes('1d')) {
                    replacement = '$3,500 → $2,100 (🔴 -40%)';
                }
            }
            if (replacement) {
                previewText = previewText.replace(variable, replacement);
            }
        });

        // Split into lines and process bold text
        const lines = previewText.split('\n');
        
        return lines.map((line, index) => {
            // Process bold text
            const parts = line.split(/(\*[^*]+\*)/g);
            const processedParts = parts.map(part => {
                if (part.startsWith('*') && part.endsWith('*')) {
                    return <strong key={part}>{part.slice(1, -1)}</strong>;
                }
                return part;
            });

            // Return each line with proper spacing
            return (
                <div key={index} style={{ 
                    marginBottom: line.trim() === '' ? '12px' : '4px',
                    whiteSpace: 'pre-wrap'
                }}>
                    {processedParts}
                </div>
            );
        });
    };

    return (
        <div className="statsbot-form">
            {/* wait for the values to load */}
            {(initialValues && initialValues.message_destination && initialValues.data_source) ?
                (<Form form={form}
                    onFinish={handleSubmit}
                    layout="vertical"
                    scrollToFirstError={true}
                    onFieldsChange={() => setShowDialog(true)}
                >
                    <div style={{ maxWidth: '70%', minWidth: '170px' }}>

                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[{ required: true, message: 'Please enter a name' }]}
                            initialValue={initialValues?.name}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Message Destination"
                            name="message_destination"
                            rules={[{ required: true, message: 'Please select a message destination' }]}
                            initialValue={initialValues?.message_destination?.id}
                        >
                            <Select defaultValue={initialValues?.message_destination?.name} showSearch onChange={handleDestinationChange}>
                                {slackAccounts ?
                                    slackAccounts.map((destination) => (
                                        <Option key={destination.id} value={destination.id}>
                                            {destination.name}
                                        </Option>
                                    )) : (
                                        <>No Items</>
                                    )
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Slack Channel"
                            name="message_channel_id"
                            rules={[{ required: true, message: 'Please enter a message channel' }]}
                            initialValue={initialValues?.message_channel_id}
                        >
                            <Select 
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => 
                                    option?.children?.toString().toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {slackChannels ?
                                    slackChannels.map((channel) => (
                                        <Option key={channel.id} value={channel.id}>
                                            #{channel.name}
                                        </Option>
                                    )) : (
                                        <>No Items</>
                                    )
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Data Source"
                            name="data_source"
                            rules={[{ required: true, message: 'Please select a data source' }]}
                            initialValue={initialValues?.data_source?.id}
                        >
                            <Select 
                                showSearch 
                                defaultValue={initialValues?.data_source?.name} 
                                onChange={ondataSourceChange}
                                optionFilterProp="label"
                                filterOption={(input, option) => 
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {dataSources ? (
                                    dataSources.map((dataSource) => (
                                        <Option 
                                            key={dataSource.id} 
                                            value={dataSource.id}
                                            label={`${dataSource.name} (${getGatewayName(dataSource.gateway_name)})`}
                                        >
                                            {dataSource.name} ({getGatewayName(dataSource.gateway_name)})
                                        </Option>
                                    ))) : (
                                    <>No Items</>
                                )}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Post Frequency"
                            name="post_frequency"
                            rules={[{ required: true, message: 'Please select a post frequency' }]}
                            initialValue={initialValues?.post_frequency}
                        >
                            <Select onChange={handlePostFrequencyChange} showSearch>
                                <Option value="Daily">Daily</Option>
                                <Option value="Daily, Weekdays Only">Daily, Weekdays Only</Option>
                                <Option value="Weekly">Weekly</Option>
                                <Option value="Monthly">Monthly</Option>
                            </Select>
                        </Form.Item>

                        {showPostDay && (
                            <Form.Item
                                label="Post Day"
                                name="post_day"
                                rules={[{ required: true, message: 'Please select a post day' }]}
                                initialValue={initialValues?.post_day ? initialValues?.post_day : 'Monday'}
                            >
                                <Select showSearch>
                                    <Option value="Sunday">Sunday</Option>
                                    <Option value="Monday">Monday</Option>
                                    <Option value="Tuesday">Tuesday</Option>
                                    <Option value="Wednesday">Wednesday</Option>
                                    <Option value="Thursday">Thursday</Option>
                                    <Option value="Friday">Friday</Option>
                                    <Option value="Saturday">Saturday</Option>
                                </Select>
                            </Form.Item>
                        )}

                        {showPostDate && (
                            <Form.Item
                                label="Post Date"
                                name="post_date"
                                rules={[{ required: true, message: 'Please select a post date' }]}
                                initialValue={initialValues?.post_date ? initialValues?.post_date : 1}
                            >
                                <Select showSearch defaultValue={initialValues?.post_date ? `Day ${initialValues?.post_date}` : 'Day 1'}>
                                    {daysArray.map((day) => (
                                        <Option key={day} value={day}>
                                            Day {day}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )}

                        <Form.Item
                            label="Post Time"
                            name="post_time"
                            rules={[{ required: true, message: 'Please select a time to send message' }]}
                            initialValue={initialValues?.post_time}
                        >
                            <Input type="time" />
                        </Form.Item>

                        <Form.Item
                            label="Timezone"
                            name="timezone"
                            rules={[{ required: true, message: 'Please select a timezone' }]}
                            initialValue={initialValues?.timezone}
                        >
                            <Select showSearch>
                                {timezones ? (
                                    timezones.map((timezone) => (
                                        <Option key={timezone} value={timezone}>
                                            {timezone}
                                        </Option>
                                    ))) : (
                                    <>No Items</>
                                )}
                            </Select>
                        </Form.Item>
                    </div>

                    <div style={{ position: "relative", marginTop: 50 }}>

                        <Form.Item
                            label="Message"
                            name="message"
                            required
                            tooltip="Enter your message with variables"
                            initialValue={isEdit ? initialValues?.message : defaultMessage}
                        >
                            <StyledMessageInput
                                id="message"
                                value={formData.message}
                                onChange={(e) => {
                                    const newValue = e.target.value;
                                    setFormData(prev => ({ ...prev, message: newValue }));
                                    form.setFieldValue('message', newValue);
                                }}
                                placeholder="Enter your message here..."
                                autoSize={{ minRows: 12, maxRows: 20 }}
                                variables={getVariablesForDataSource(currentDataSourceCategory)}
                                onVariableSelect={handleVariableSelect}
                            />
                        </Form.Item>

                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '8px', marginBottom: '24px' }}>
                            <Button type="primary" htmlType="submit" loading={isLoading}>
                                {isEdit ? "Update" : "Create"}
                            </Button>
                            <Button 
                                className="statbot-button" 
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} 
                                onClick={sendTestMessageToSlack}
                            >
                                <img class="button-image" alt="slack-icon" src="/static/slack.png"></img>
                                <span>Send Test Message</span>
                            </Button>
                        </div>

                        <div style={{ marginTop: '32px' }}>
                            <div style={{ marginBottom: '12px', color: '#666' }}>
                                Preview of how your message will appear in Slack (showing dummy data):
                            </div>
                            <div style={{
                                backgroundColor: '#f8f9f9',
                                padding: '20px',
                                borderRadius: '8px',
                                border: '1px solid #e8e8e8',
                            }}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    gap: '12px'
                                }}>
                                    {/* Bot Avatar */}
                                    <div style={{
                                        width: '36px',
                                        height: '36px',
                                        backgroundColor: '#e8e8e8',
                                        borderRadius: '4px',
                                        flexShrink: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <img 
                                            src="/static/slack.png" 
                                            alt="Bot" 
                                            style={{ 
                                                width: '20px', 
                                                height: '20px',
                                                opacity: 0.7 
                                            }} 
                                        />
                                    </div>
                                    
                                    {/* Message Content */}
                                    <div style={{ flex: 1 }}>
                                        <div style={{
                                            fontWeight: 700,
                                            fontSize: '15px',
                                            color: '#1d1c1d',
                                            marginBottom: '4px'
                                        }}>
                                            StatsBot.xyz
                                        </div>
                                        <div style={{
                                            fontFamily: '-apple-system, BlinkMacSystemFont, Slack-Lato, "Slack-Lato", "Segoe UI", Roboto, sans-serif',
                                            fontSize: '15px',
                                            lineHeight: '1.46668',
                                            color: 'rgb(29, 28, 29)',
                                            whiteSpace: 'pre-wrap',
                                            wordWrap: 'break-word'
                                        }}>
                                            {renderPreviewMessage()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Metrics variables section - Temporarily hidden while we update the variable selector UI
                    <div style={{ marginBottom: '20px' }}>
                        <h4>Metrics variables: (click to copy to clipboard)</h4>
                        <p>The list below shows commonly used variables. See a full list of available variables along with their definitions in <a href={currentDataSourceCategory === 'shopify_partner' ? 'https://docs.google.com/spreadsheets/d/131yUdea4eH-6cuLtgmh4Vy1VSEMx3eGuW7Zfwd1wV_Y/edit?gid=552193353#gid=552193353' : 'https://docs.google.com/spreadsheets/d/131yUdea4eH-6cuLtgmh4Vy1VSEMx3eGuW7Zfwd1wV_Y/edit?usp=sharing'} target="_blank" rel="noopener noreferrer">this help article</a>.</p>
                        
                        { currentDataSourceCategory === 'stripe' && <StripeMetricGrid /> }
                        { currentDataSourceCategory === 'shopify_partner' && <ShopifyPartnerMetricGrid /> }
                    </div> */}
                </Form>)
                : (
                    <Spin />
                )}
        </div>
    )
}

export default StatsBotForm;