import { React, useEffect, useState } from 'react';
import { Layout, Menu } from 'antd';
import { RobotOutlined, ApiOutlined, DollarOutlined } from "@ant-design/icons";
import { useLocation, Outlet, useNavigate } from 'react-router-dom';

const { Content, Sider } = Layout;


const Dashboard = () => {

    const navigate = useNavigate();

    const location = useLocation();
    const [currentActive, setCurrentActive] = useState('');

    useEffect(() => {
        const path = location.pathname;
        switch (path) {
            case '/statsbot':
                setCurrentActive('statsbot');
                break;
            case '/integration':
                setCurrentActive('integration');
                break;
            case '/billing':
                setCurrentActive('billing');
                break;
            default:
                setCurrentActive('statsbot');
        }
    }, [location]);

    return (
        <div>
            <Layout>
                <Sider breakpoint="lg" className='sider' style={{
                    backgroundColor : 'white',
                }}>
                    <div class='dashboard-sider'>
                        <Menu className='sider-menu' selectedKeys={[currentActive]}>
                            <Menu.Item key="statsbot"
                                className="sider-button"
                                icon={<RobotOutlined />}
                                onClick={() => { navigate('/statsbot') }}>
                                StatsBot
                            </Menu.Item>
                            <Menu.Item
                                key="integration"
                                className="sider-button"
                                icon={<ApiOutlined />}
                                onClick={() => { navigate('/integration') }}>
                                Integrations
                            </Menu.Item>
                            <Menu.Item
                                key="billing"
                                className="sider-button"
                                icon={<DollarOutlined />}
                                onClick={() => { navigate('/billing') }}>
                                Billing
                            </Menu.Item>
                        </Menu>
                    </div>
                </Sider>
                <Content className='content'>
                    <div class='dashboard-content'>
                        <Outlet />
                    </div>
                </Content>
            </Layout>
        </div>
    );
};

export default Dashboard;