import React from "react";
import { message } from "antd";
import './metricGrid.css';

const MetricSection = ({ title, variables }) => {
    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        message.info("Copied to clipboard.", 1);
    };

    return (
        <div style={{ 
            backgroundColor: '#f5f5f5', 
            padding: '15px', 
            borderRadius: '8px',
            border: '1px solid #e8e8e8'
        }}>
            <h5 style={{ marginBottom: '10px' }}>{title}</h5>
            <div style={{ display: 'grid', gap: '5px' }}>
                {variables.map((variable, index) => (
                    <code 
                        key={index} 
                        onClick={() => copyToClipboard(variable)}
                        className="metric-variable"
                    >
                        {variable}
                    </code>
                ))}
            </div>
        </div>
    );
};

export const StripeMetricGrid = () => (
    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px' }}>
        <MetricSection 
            title="App Net Revenue"
            variables={[
                '{date}',
                '{appname}',
                '{appnetrevenue365d}',
                '{appnetrevenue365d+previousperiod+change%}',
                '{appnetrevenue28d}',
                '{appnetrevenue28d+previousperiod+change%}',
                '{appnetrevenue7d}',
                '{appnetrevenue7d+previousperiod+change%}',
                '{appnetrevenue1d}',
                '{appnetrevenue1d+previousperiod+change%}'
            ]}
        />
        <MetricSection 
            title="App Subscription Revenue"
            variables={[
                '{appsubscriptionrevenue365d}',
                '{appsubscriptionrevenue365d+previousperiod+change%}',
                '{appsubscriptionrevenue28d}',
                '{appsubscriptionrevenue28d+previousperiod+change%}',
                '{appsubscriptionrevenue7d}',
                '{appsubscriptionrevenue7d+previousperiod+change%}',
                '{appsubscriptionrevenue1d}',
                '{appsubscriptionrevenue1d+previousperiod+change%}'
            ]}
        />
        <MetricSection 
            title="App Usage Revenue"
            variables={[
                '{appusagerevenue365d}',
                '{appusagerevenue365d+previousperiod+change%}',
                '{appusagerevenue28d}',
                '{appusagerevenue28d+previousperiod+change%}',
                '{appusagerevenue7d}',
                '{appusagerevenue7d+previousperiod+change%}',
                '{appusagerevenue1d}',
                '{appusagerevenue1d+previousperiod+change%}'
            ]}
        />
        <MetricSection 
            title="App Paying Stores Count"
            variables={[
                '{apppayingstorescount365d}',
                '{apppayingstorescount365d+previousperiod+change%}',
                '{apppayingstorescount28d}',
                '{apppayingstorescount28d+previousperiod+change%}',
                '{apppayingstorescount7d}',
                '{apppayingstorescount7d+previousperiod+change%}',
                '{apppayingstorescount1d}',
                '{apppayingstorescount1d+previousperiod+change%}'
            ]}
        />
        <MetricSection 
            title="App ARPU"
            variables={[
                '{apparpu365d}',
                '{apparpu365d+previousperiod+change%}',
                '{apparpu28d}',
                '{apparpu28d+previousperiod+change%}',
                '{apparpu7d}',
                '{apparpu7d+previousperiod+change%}',
                '{apparpu1d}',
                '{apparpu1d+previousperiod+change%}'
            ]}
        />
    </div>
);

export const ShopifyPartnerMetricGrid = () => (
    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px' }}>
        <MetricSection 
            title="App Net Revenue"
            variables={[
                '{date}',
                '{appname}',
                '{appnetrevenue365d}',
                '{appnetrevenue365d+previousperiod+change%}',
                '{appnetrevenue28d}',
                '{appnetrevenue28d+previousperiod+change%}',
                '{appnetrevenue7d}',
                '{appnetrevenue7d+previousperiod+change%}',
                '{appnetrevenue1d}',
                '{appnetrevenue1d+previousperiod+change%}'
            ]}
        />
        <MetricSection 
            title="App Subscription Revenue"
            variables={[
                '{appsubscriptionrevenue365d}',
                '{appsubscriptionrevenue365d+previousperiod+change%}',
                '{appsubscriptionrevenue28d}',
                '{appsubscriptionrevenue28d+previousperiod+change%}',
                '{appsubscriptionrevenue7d}',
                '{appsubscriptionrevenue7d+previousperiod+change%}',
                '{appsubscriptionrevenue1d}',
                '{appsubscriptionrevenue1d+previousperiod+change%}'
            ]}
        />
        <MetricSection 
            title="App Usage Revenue"
            variables={[
                '{appusagerevenue365d}',
                '{appusagerevenue365d+previousperiod+change%}',
                '{appusagerevenue28d}',
                '{appusagerevenue28d+previousperiod+change%}',
                '{appusagerevenue7d}',
                '{appusagerevenue7d+previousperiod+change%}',
                '{appusagerevenue1d}',
                '{appusagerevenue1d+previousperiod+change%}'
            ]}
        />
        <MetricSection 
            title="App Paying Stores Count"
            variables={[
                '{apppayingstorescount365d}',
                '{apppayingstorescount365d+previousperiod+change%}',
                '{apppayingstorescount28d}',
                '{apppayingstorescount28d+previousperiod+change%}',
                '{apppayingstorescount7d}',
                '{apppayingstorescount7d+previousperiod+change%}',
                '{apppayingstorescount1d}',
                '{apppayingstorescount1d+previousperiod+change%}'
            ]}
        />
        <MetricSection 
            title="App ARPU"
            variables={[
                '{apparpu365d}',
                '{apparpu365d+previousperiod+change%}',
                '{apparpu28d}',
                '{apparpu28d+previousperiod+change%}',
                '{apparpu7d}',
                '{apparpu7d+previousperiod+change%}',
                '{apparpu1d}',
                '{apparpu1d+previousperiod+change%}'
            ]}
        />
    </div>
);