export const stripeVariables = {
  categories: [
    {
      name: "General",
      variables: [
        {
          key: "date",
          label: "Today's Date",
          description: "Today's Date",
          example: "2024-01-15"
        }
      ]
    },
    {
      name: "Subscriptions",
      variables: [
        {
          key: "activesubscriptions",
          label: "Active Subscriptions",
          description: "Number of active subscriptions (includes subscriptions set to cancel at period end)",
          example: "42"
        },
        {
          key: "activesubscriptionsexcludingcancel",
          label: "Active Subscriptions (excl. cancellations)",
          description: "Number of active subscriptions (excludes subscriptions set to cancel at period end)",
          example: "38"
        },
        {
          key: "activemonthlysubscriptions",
          label: "Monthly Subscriptions",
          description: "Number of active monthly subscriptions",
          example: "25"
        },
        {
          key: "activequarterlysubscriptions",
          label: "Quarterly Subscriptions",
          description: "Number of active quarterly subscriptions",
          example: "5"
        },
        {
          key: "activesemiannualsubscriptions",
          label: "Semi-annual Subscriptions",
          description: "Number of active semi-annual subscriptions",
          example: "3"
        },
        {
          key: "activeyearlysubscriptions",
          label: "Yearly Subscriptions",
          description: "Number of active yearly subscriptions",
          example: "9"
        },
        {
          key: "trialsubscriptions",
          label: "Trial Subscriptions",
          description: "Number of subscriptions currently in trial period",
          example: "15"
        }
      ]
    },
    {
      name: "Revenue",
      variables: [
        {
          key: "mrr",
          label: "MRR",
          description: "Monthly Recurring Revenue - Total revenue from active monthly subscriptions",
          example: "$4,999"
        },
        {
          key: "arr",
          label: "ARR",
          description: "Annual Recurring Revenue - Estimated revenue for the year based on current subscriptions",
          example: "$59,988"
        },
        {
          key: "trialmrr",
          label: "Trial MRR",
          description: "Monthly Recurring Revenue from trial subscriptions (potential MRR if all trials convert)",
          example: "$1,500"
        },
        {
          key: "trialarr",
          label: "Trial ARR",
          description: "Annual Recurring Revenue from trial subscriptions (potential ARR if all trials convert)",
          example: "$18,000"
        },
        {
          key: "grossrevenue",
          label: "Gross Revenue",
          description: "Total revenue before refunds and fees",
          example: "$6,000"
        },
        {
          key: "revenue",
          label: "Revenue",
          description: "Total revenue after refunds, but before fees",
          example: "$5,500"
        },
        {
          key: "netrevenue",
          label: "Net Revenue",
          description: "Total revenue after refunds, chargebacks and all fees",
          example: "$5,200"
        }
      ]
    },
    {
      name: "Forecasts",
      variables: [
        {
          key: "forecastmonthendmrr",
          label: "Month-end MRR Forecast",
          description: "Expected MRR by end of current month (based on current active subscriptions minus known cancellations)",
          example: "$5,200"
        },
        {
          key: "forecastnextmonthmrr",
          label: "Next Month MRR Forecast",
          description: "Expected MRR for next month (based on current active subscriptions minus known cancellations)",
          example: "$5,500"
        },
        {
          key: "forecastyearendrevenue",
          label: "Year-end Revenue Forecast",
          description: "Expected total revenue by end of year (based on current active subscriptions minus known cancellations)",
          example: "$65,000"
        }
      ]
    },
    {
      name: "Transactions",
      variables: [
        {
          key: "chargevalue",
          label: "Charge Value",
          description: "Total value of successful credit card charges",
          example: "$5,500"
        },
        {
          key: "chargecount",
          label: "Charge Count",
          description: "Number of successful credit card charges",
          example: "150"
        },
        {
          key: "paymentvalue",
          label: "Payment Value",
          description: "Total value of successful alternative payment method charges",
          example: "$2,500"
        },
        {
          key: "paymentcount",
          label: "Payment Count",
          description: "Number of successful alternative payment method charges",
          example: "50"
        },
        {
          key: "totalcount",
          label: "Total Count",
          description: "Total number of successful charges (chargecount + paymentcount)",
          example: "200"
        },
        {
          key: "pendingchargevalue",
          label: "Pending Charge Value",
          description: "Total value of pending charges",
          example: "$500"
        }
      ]
    },
    {
      name: "Refunds & Disputes",
      variables: [
        {
          key: "refundcount",
          label: "Refund Count",
          description: "Number of payments refunded to the customers",
          example: "5"
        },
        {
          key: "refundvalue",
          label: "Refund Value",
          description: "Total value of refunds",
          example: "$500"
        },
        {
          key: "refundfailurevalue",
          label: "Failed Refund Value",
          description: "Total value of failed refunds",
          example: "$100"
        },
        {
          key: "disputecount",
          label: "Dispute Count",
          description: "Number of disputes",
          example: "2"
        },
        {
          key: "disputevalue",
          label: "Dispute Value",
          description: "Total value of disputes",
          example: "$300"
        },
        {
          key: "disputewoncount",
          label: "Won Dispute Count",
          description: "Number of disputes won",
          example: "1"
        },
        {
          key: "disputewonvalue",
          label: "Won Dispute Value",
          description: "Total value of disputes won",
          example: "$150"
        }
      ]
    },
    {
      name: "Fees & Payouts",
      variables: [
        {
          key: "stripefee",
          label: "Stripe Fee",
          description: "Total fees charged by Stripe",
          example: "$150"
        },
        {
          key: "taxfee",
          label: "Tax Fee",
          description: "Total taxes charged",
          example: "$100"
        },
        {
          key: "payoutvalue",
          label: "Payout Value",
          description: "Total value of payouts (payments from stripe account to your bank account)",
          example: "$5,000"
        },
        {
          key: "payoutreversalvalue",
          label: "Payout Reversal Value",
          description: "Total value of reversed payouts (Payouts canceled or failure)",
          example: "$200"
        }
      ]
    },
    {
      name: "Time-based Metrics (1 Day)",
      variables: [
        {
          key: "revenue1d",
          label: "Revenue (1d)",
          description: "Total revenue generated in the last day",
          example: "$500"
        },
        {
          key: "paymentscount1d",
          label: "Payments Count (1d)",
          description: "Number of successful payments made in the last day",
          example: "15"
        },
        {
          key: "arpu1d",
          label: "ARPU (1d)",
          description: "Average revenue per user in the last day",
          example: "$33"
        },
        {
          key: "revenue1d+previousperiod+change%",
          label: "Revenue Change (1d)",
          description: "Total revenue generated in the last day compared to a previous period, with the percentage change",
          example: "$500 (+15%)"
        },
        {
          key: "paymentscount1d+previousperiod+change%",
          label: "Payments Count Change (1d)",
          description: "Number of successful payments made in the last day compared to a previous period, with the percentage change",
          example: "15 (+10%)"
        },
        {
          key: "arpu1d+previousperiod+change%",
          label: "ARPU Change (1d)",
          description: "Average revenue per user in the last day compared to a previous period, with the percentage change",
          example: "$33 (+5%)"
        }
      ]
    },
    {
      name: "Time-based Metrics (7 Days)",
      variables: [
        {
          key: "revenue7d",
          label: "Revenue (7d)",
          description: "Total revenue generated in the last 7 days",
          example: "$3,500"
        },
        {
          key: "paymentscount7d",
          label: "Payments Count (7d)",
          description: "Number of successful payments made in the last 7 days",
          example: "105"
        },
        {
          key: "arpu7d",
          label: "ARPU (7d)",
          description: "Average revenue per user in the last 7 days",
          example: "$33"
        },
        {
          key: "revenue7d+previousperiod+change%",
          label: "Revenue Change (7d)",
          description: "Total revenue generated in the last 7 days compared to a previous period, with the percentage change",
          example: "$3,500 (+15%)"
        },
        {
          key: "paymentscount7d+previousperiod+change%",
          label: "Payments Count Change (7d)",
          description: "Number of successful payments made in the last 7 days compared to a previous period, with the percentage change",
          example: "105 (+10%)"
        },
        {
          key: "arpu7d+previousperiod+change%",
          label: "ARPU Change (7d)",
          description: "Average revenue per user in the last 7 days compared to a previous period, with the percentage change",
          example: "$33 (+5%)"
        }
      ]
    },
    {
      name: "Time-based Metrics (28 Days)",
      variables: [
        {
          key: "revenue28d",
          label: "Revenue (28d)",
          description: "Total revenue generated in the last 28 days",
          example: "$14,000"
        },
        {
          key: "paymentscount28d",
          label: "Payments Count (28d)",
          description: "Number of successful payments made in the last 28 days",
          example: "420"
        },
        {
          key: "arpu28d",
          label: "ARPU (28d)",
          description: "Average revenue per user in the last 28 days",
          example: "$33"
        },
        {
          key: "revenue28d+previousperiod+change%",
          label: "Revenue Change (28d)",
          description: "Total revenue generated in the last 28 days compared to a previous period, with the percentage change",
          example: "$14,000 (+15%)"
        },
        {
          key: "paymentscount28d+previousperiod+change%",
          label: "Payments Count Change (28d)",
          description: "Number of successful payments made in the last 28 days compared to a previous period, with the percentage change",
          example: "420 (+10%)"
        },
        {
          key: "arpu28d+previousperiod+change%",
          label: "ARPU Change (28d)",
          description: "Average revenue per user in the last 28 days compared to a previous period, with the percentage change",
          example: "$33 (+5%)"
        }
      ]
    },
    {
      name: "Time-based Metrics (365 Days)",
      variables: [
        {
          key: "revenue365d",
          label: "Revenue (365d)",
          description: "Total revenue generated in the last 365 days",
          example: "$168,000"
        },
        {
          key: "paymentscount365d",
          label: "Payments Count (365d)",
          description: "Number of successful payments made in the last 365 days",
          example: "5,040"
        },
        {
          key: "arpu365d",
          label: "ARPU (365d)",
          description: "Average revenue per user in the last 365 days",
          example: "$33"
        },
        {
          key: "revenue365d+previousperiod+change%",
          label: "Revenue Change (365d)",
          description: "Total revenue generated in the last 365 days compared to a previous period, with the percentage change",
          example: "$168,000 (+15%)"
        },
        {
          key: "paymentscount365d+previousperiod+change%",
          label: "Payments Count Change (365d)",
          description: "Number of successful payments made in the last 365 days compared to a previous period, with the percentage change",
          example: "5,040 (+10%)"
        },
        {
          key: "arpu365d+previousperiod+change%",
          label: "ARPU Change (365d)",
          description: "Average revenue per user in the last 365 days compared to a previous period, with the percentage change",
          example: "$33 (+5%)"
        }
      ]
    }
  ]
};

export const shopifyPartnerVariables = {
  categories: [
    {
      name: "General",
      variables: [
        {
          key: "date",
          label: "Date",
          description: "The date of the reported statistics",
          example: "2024-01-15"
        },
        {
          key: "appname",
          label: "App Name",
          description: "Name of the Shopify app",
          example: "My Shopify App"
        }
      ]
    },
    {
      name: "Overall Revenue (1 Day)",
      variables: [
        {
          key: "grossrevenue1d",
          label: "Gross Revenue",
          description: "Total gross revenue across all sources in the last day",
          example: "$5,000"
        },
        {
          key: "netrevenue1d",
          label: "Net Revenue",
          description: "Total net revenue across all sources in the last day",
          example: "$4,250"
        },
        {
          key: "netrevenue1d+previousperiod+change%",
          label: "Net Revenue Change",
          description: "Net revenue change compared to previous period",
          example: "$4,000 → $4,250 (+6.25%)"
        },
        {
          key: "totalpayingstores1d",
          label: "Total Paying Stores",
          description: "Total number of stores that made any payment",
          example: "150"
        },
        {
          key: "arpu1d",
          label: "Overall ARPU",
          description: "Average Revenue Per User across all revenue sources",
          example: "$28.33"
        },
        {
          key: "arpu1d+previousperiod+change%",
          label: "Overall ARPU Change",
          description: "ARPU change compared to previous period",
          example: "$25.50 → $28.33 (+11.1%)"
        }
      ]
    },
    {
      name: "App Revenue (1 Day)",
      variables: [
        {
          key: "appgrossrevenue1d",
          label: "App Gross Revenue",
          description: "Gross revenue from app sales",
          example: "$3,500"
        },
        {
          key: "appnetrevenue1d",
          label: "App Net Revenue",
          description: "Net revenue from app sales after deductions",
          example: "$2,975"
        },
        {
          key: "appnetrevenue1d+previousperiod+change%",
          label: "App Net Revenue Change",
          description: "App net revenue change compared to previous period",
          example: "$2,800 → $2,975 (+6.25%)"
        },
        {
          key: "appsubscriptionrevenue1d",
          label: "Subscription Revenue",
          description: "Revenue from app subscriptions",
          example: "$2,500"
        },
        {
          key: "appsubscriptionrevenue1d+previousperiod+change%",
          label: "Subscription Revenue Change",
          description: "Subscription revenue change compared to previous period",
          example: "$2,300 → $2,500 (+8.7%)"
        },
        {
          key: "appusagerevenue1d",
          label: "Usage Revenue",
          description: "Revenue from app usage charges",
          example: "$475"
        },
        {
          key: "appusagerevenue1d+previousperiod+change%",
          label: "Usage Revenue Change",
          description: "Usage revenue change compared to previous period",
          example: "$500 → $475 (-5%)"
        },
        {
          key: "apppayingstorescount1d",
          label: "App Paying Stores",
          description: "Number of stores that paid for the app",
          example: "120"
        },
        {
          key: "apppayingstorescount1d+previousperiod+change%",
          label: "App Paying Stores Change",
          description: "Change in number of paying stores",
          example: "110 → 120 (+9.1%)"
        },
        {
          key: "apppaymentsreceived1d",
          label: "App Payments Count",
          description: "Number of payments received for the app",
          example: "125"
        },
        {
          key: "appadjustedvalue1d",
          label: "App Adjustments",
          description: "Value of adjustments made to app revenue",
          example: "$50"
        },
        {
          key: "appadjustmentcount1d",
          label: "App Adjustment Count",
          description: "Number of adjustments made to app revenue",
          example: "3"
        },
        {
          key: "apparpu1d",
          label: "App ARPU",
          description: "Average Revenue Per User for app revenue",
          example: "$24.79"
        },
        {
          key: "apparpu1d+previousperiod+change%",
          label: "App ARPU Change",
          description: "App ARPU change compared to previous period",
          example: "$23.50 → $24.79 (+5.5%)"
        }
      ]
    },
    {
      name: "Service Revenue (1 Day)",
      variables: [
        {
          key: "servicenetrevenue1d",
          label: "Service Net Revenue",
          description: "Net revenue from services",
          example: "$800"
        },
        {
          key: "servicenetrevenue1d+previousperiod+change%",
          label: "Service Net Revenue Change",
          description: "Service revenue change compared to previous period",
          example: "$750 → $800 (+6.7%)"
        },
        {
          key: "servicegrossrevenue1d",
          label: "Service Gross Revenue",
          description: "Gross revenue from services",
          example: "$1,000"
        },
        {
          key: "servicepayingstorescount1d",
          label: "Service Paying Stores",
          description: "Number of stores that paid for services",
          example: "20"
        },
        {
          key: "servicepaymentcount1d",
          label: "Service Payments Count",
          description: "Number of payments received for services",
          example: "22"
        },
        {
          key: "serviceadjustedvalue1d",
          label: "Service Adjustments",
          description: "Value of adjustments made to service revenue",
          example: "$25"
        },
        {
          key: "serviceadjustmentcount1d",
          label: "Service Adjustment Count",
          description: "Number of adjustments made to service revenue",
          example: "2"
        },
        {
          key: "servicearpu1d",
          label: "Service ARPU",
          description: "Average Revenue Per User for services",
          example: "$40"
        },
        {
          key: "servicearpu1d+previousperiod+change%",
          label: "Service ARPU Change",
          description: "Service ARPU change compared to previous period",
          example: "$37.50 → $40.00 (+6.7%)"
        }
      ]
    },
    {
      name: "Theme Revenue (1 Day)",
      variables: [
        {
          key: "themenetrevenue1d",
          label: "Theme Net Revenue",
          description: "Net revenue from themes",
          example: "$475"
        },
        {
          key: "themenetrevenue1d+previousperiod+change%",
          label: "Theme Net Revenue Change",
          description: "Theme revenue change compared to previous period",
          example: "$450 → $475 (+5.6%)"
        },
        {
          key: "themegrossrevenue1d",
          label: "Theme Gross Revenue",
          description: "Gross revenue from themes",
          example: "$500"
        },
        {
          key: "themepayingstorescount1d",
          label: "Theme Paying Stores",
          description: "Number of stores that paid for themes",
          example: "10"
        },
        {
          key: "themepaymentcount1d",
          label: "Theme Payments Count",
          description: "Number of payments received for themes",
          example: "10"
        },
        {
          key: "themeadjustedvalue1d",
          label: "Theme Adjustments",
          description: "Value of adjustments made to theme revenue",
          example: "$25"
        },
        {
          key: "themeadjustmentcount1d",
          label: "Theme Adjustment Count",
          description: "Number of adjustments made to theme revenue",
          example: "1"
        },
        {
          key: "themearpu1d",
          label: "Theme ARPU",
          description: "Average Revenue Per User for themes",
          example: "$47.50"
        },
        {
          key: "themearpu1d+previousperiod+change%",
          label: "Theme ARPU Change",
          description: "Theme ARPU change compared to previous period",
          example: "$45.00 → $47.50 (+5.6%)"
        }
      ]
    },
    {
      name: "Other (1 Day)",
      variables: [
        {
          key: "taxvalue1d",
          label: "Tax Value",
          description: "Total tax collected",
          example: "$250"
        },
        {
          key: "referralamount1d",
          label: "Referral Amount",
          description: "Revenue from referrals",
          example: "$100"
        },
        {
          key: "referraladjustedvalue1d",
          label: "Referral Adjustments",
          description: "Adjustments made to referral revenue",
          example: "$10"
        }
      ]
    }
  ]
};

// Helper function to generate time-based categories
function generateTimeBasedCategory(days) {
  const baseCategory = shopifyPartnerVariables.categories.slice(1); // Skip General category
  return baseCategory.map(category => ({
    name: category.name.replace("(1 Day)", `(${days} Days)`),
    variables: category.variables.map(variable => ({
      ...variable,
      key: variable.key.replace("1d", `${days}d`),
      label: variable.label,
      description: variable.description.replace("last day", `last ${days} days`),
      example: variable.example // Keep same example values for simplicity
    }))
  }));
}

// Add time-based categories for 7, 28, and 365 days
[7, 28, 365].forEach(days => {
  shopifyPartnerVariables.categories.push(...generateTimeBasedCategory(days));
}); 