import React from 'react';
import { Select, Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const { Panel } = Collapse;

const StyledCollapse = styled(Collapse)`
  border: none !important;
  background: none !important;

  .ant-collapse-item {
    border: none !important;
  }

  .ant-collapse-header {
    padding: 12px 16px !important;
    background: #f7f7f7;
    border-bottom: 1px solid #e8e8e8;
    font-weight: 600;
    color: #1f1f1f !important;
    font-size: 14px;
    flex-direction: row-reverse !important;
    align-items: center !important;
  }

  .ant-collapse-content {
    border: none !important;
  }

  .ant-collapse-content-box {
    padding: 0 !important;
  }

  .ant-collapse-expand-icon {
    color: #666;
    margin-right: 0 !important;
    margin-left: 8px !important;
  }
`;

const VariableItem = styled.div`
  padding: 16px;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background: #fafafa;
  }
`;

const VariableBox = styled.code`
  display: inline-block;
  background-color: #f7f7f7;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  padding: 2px 6px;
  font-family: monospace;
  font-size: 13px;
  color: #444;
`;

const VariableLabel = styled.div`
  display: grid;
  gap: 8px;
`;

const MainLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 4px;

  span {
    font-weight: 500;
    color: #2d2d2d;
    font-size: 14px;
  }
`;

const DetailsRow = styled.div`
  display: flex;
  align-items: baseline;
  gap: 8px;
  color: #666;
  font-size: 13px;
  line-height: 1.5;

  strong {
    color: #444;
    font-weight: 500;
  }
`;

const SearchInput = styled(Select)`
  margin-bottom: 8px;

  .ant-select-selector {
    height: 48px !important;
    padding: 8px 12px !important;
    border-radius: 4px !important;
  }

  .ant-select-selection-search {
    height: 48px !important;
  }

  .ant-select-selection-search-input {
    height: 48px !important;
    font-size: 14px !important;
  }

  .ant-select-selection-placeholder {
    line-height: 32px !important;
    color: #666;
    font-size: 14px;
  }
`;

const SearchBox = styled.input`
  width: 100%;
  height: 48px;
  padding: 8px 12px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  font-size: 14px;
  color: #2d2d2d;
  outline: none;
  margin-bottom: 8px;

  &::placeholder {
    color: #666;
  }

  &:focus {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
`;

const NoDataFound = styled.div`
  padding: 24px;
  text-align: center;
  color: #666;
  font-size: 14px;
`;

const Container = styled.div`
  padding: 16px;
`;

const VariableSelector = ({ variables, onSelect }) => {
  const [searchValue, setSearchValue] = React.useState('');
  const [activeKeys, setActiveKeys] = React.useState(['0']); // First category open by default

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const filteredVariables = React.useMemo(() => {
    if (!searchValue) return variables.categories;

    const searchTerm = searchValue.toLowerCase();
    return variables.categories.map(category => ({
      ...category,
      variables: category.variables.filter(variable => 
        variable.key.toLowerCase().includes(searchTerm) ||
        variable.label.toLowerCase().includes(searchTerm) ||
        variable.description.toLowerCase().includes(searchTerm)
      )
    })).filter(category => category.variables.length > 0);
  }, [variables, searchValue]);

  return (
    <Container>
      <SearchBox
        type="text"
        placeholder="Search for a variable..."
        onChange={handleSearch}
        value={searchValue}
      />
      {filteredVariables.length > 0 ? (
        <StyledCollapse
          defaultActiveKey={['0']}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} style={{ fontSize: 12 }} />
          )}
          activeKey={activeKeys}
          onChange={setActiveKeys}
        >
          {filteredVariables.map((category, index) => (
            <Panel header={`${index + 1}. ${category.name}`} key={index.toString()}>
              {category.variables.map((variable) => (
                <VariableItem key={variable.key} onClick={() => onSelect(`{${variable.key}}`)}>
                  <VariableLabel>
                    <MainLabel>
                      <VariableBox>{`{${variable.key}}`}</VariableBox>
                      <span>{variable.label}</span>
                    </MainLabel>
                    <DetailsRow>
                      {variable.description}
                      {variable.example && (
                        <>
                          <span>•</span>
                          <strong>Example:</strong> {variable.example}
                        </>
                      )}
                    </DetailsRow>
                  </VariableLabel>
                </VariableItem>
              ))}
            </Panel>
          ))}
        </StyledCollapse>
      ) : (
        <NoDataFound>
          No variables found matching "{searchValue}"
        </NoDataFound>
      )}
    </Container>
  );
};

export default VariableSelector; 