import { useState } from "react";
import { Form, Input, Button, message } from "antd";
import { Row, Col } from "antd";
import axios, { setTokenToAxios } from "utils/axios";
import { Alert } from "antd";
import { useNavigate } from "react-router-dom";
import { Navbar } from "Components/common";
import { useAuth } from "hooks/useAuth";

const tailLayout = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 18, offset: 3 },
    md: { span: 18, offset: 3 },
  },
};

const Signup = () => {
  let navigate = useNavigate();
  const { fetchCurrentUser } = useAuth();

  const [signupInProgress, setsignupInProgress] = useState(false);
  const [errorInSignup, seterrorInSignup] = useState(false);

  const onFinish = async (values) => {
    if (signupInProgress) {
      return;
    }
    seterrorInSignup(false);
    setsignupInProgress(true);

    try {
      var res = await axios.post("/accounts/signup", values);

      if (res && res.data.auth_token) {
        localStorage.setItem("RS_auth_token", res.data.auth_token);
        setTokenToAxios(res.data.auth_token);
        fetchCurrentUser();
      } else if (res.data.redirect_to_login) {
        navigate("/login");
      } else {
        message.error("Something went wrong.");
      }
    } catch (error) {
      setsignupInProgress(false);
      if (
        error.response &&
        error.response.data &&
        error.response.data.detail == "Invalid license key"
      ) {
        seterrorInSignup(true);
      } else {
        message.error(error.response.data.detail);
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    message.error("Something went wrong.");
  };

  return (
    <>
      <Navbar />
      <Row>
        <Col
          xs={{ span: 16, offset: 4 }}
          sm={{ span: 12, offset: 6 }}
          md={{ span: 6, offset: 9 }}
        >
          <h4 className="form-header">Create your account</h4>
          <Form
            name="signup"
            layout="vertical"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please enter your email address",
                },
                {
                  type: "email",
                  message: "Please enter a valid email address",
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
                {
                  min: 6,
                  message: "Password must be minimum of 6 characters",
                },
              ]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>

            {errorInSignup ? (
              <Alert
                message="Invalid license key"
                description={
                  <p>
                    Looks like the license key you've added is invalid. Please
                    check your order receipt from Gumroad for the license key,
                    or{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="mailto:hello@statsbot.xyz?subject=StatsBot%3A%20Contact%20Support&body=Hi%2C%20I%20need%20help%20with%20StatsBot."
                    >
                      contact us
                    </a>
                    .
                  </p>
                }
                type="warning"
                showIcon
                closable
                style={{ marginBottom: "1rem" }}
              />
            ) : null}

            <Form.Item {...tailLayout}>
              <Button type="primary" block htmlType="submit">
                {signupInProgress ? "Creating account . . ." : "Create account"}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>

      <div style={{ 
        textAlign: "center", 
        marginTop: 32,
        padding: "24px 0",
        borderTop: "1px solid #f0f0f0",
        background: "#fafafa"
      }}>
        <p style={{ 
          fontSize: "14px", 
          margin: "12px 0",
          color: "#595959"
        }}>
          Need help?{" "}
          <a 
            href="mailto:hello@statsbot.xyz?subject=StatsBot%3A%20Contact%20Support&body=Hi%2C%20I%20need%20help%20with%20StatsBot."
            style={{
              color: "#1890ff",
              textDecoration: "none",
              fontWeight: 500,
              transition: "color 0.3s"
            }}
            onMouseEnter={(e) => e.target.style.color = "#40a9ff"}
            onMouseLeave={(e) => e.target.style.color = "#1890ff"}
          >
            Contact support
          </a>
        </p>
        <p style={{ 
          fontSize: "14px", 
          margin: "12px 0",
          color: "#595959"
        }}>
          Want to know more?{" "}
          <a 
            href="https://statsbot.xyz" 
            target="_blank" 
            rel="noopener noreferrer"
            style={{
              color: "#1890ff",
              textDecoration: "none",
              fontWeight: 500,
              transition: "color 0.3s"
            }}
            onMouseEnter={(e) => e.target.style.color = "#40a9ff"}
            onMouseLeave={(e) => e.target.style.color = "#1890ff"}
          >
            Learn more
          </a>
        </p>
      </div>
    </>
  );
};

export default Signup;
