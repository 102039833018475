/**
 * @fileoverview StyledMessageInput Component
 * 
 * This component provides a rich text input experience for composing messages with variables.
 * It's primarily used in the StatsBotForm for composing messages that include dynamic data
 * from different data sources (Stripe, Shopify Partner).
 * 
 * Key Features:
 * - Variable insertion via a + button in the top right
 * - Automatic styling of variables in the text
 * - Popover selector for available variables
 * - Data source specific variables
 * 
 * @example
 * // Basic usage
 * <StyledMessageInput
 *   value={message}
 *   onChange={(e) => setMessage(e.target.value)}
 *   variables={stripeVariables}
 *   onVariableSelect={handleVariableSelect}
 * />
 * 
 * @example
 * // With all props
 * <StyledMessageInput
 *   value={message}
 *   onChange={handleChange}
 *   variables={variables}
 *   onVariableSelect={handleSelect}
 *   placeholder="Enter your message..."
 *   autoSize={{ minRows: 6, maxRows: 12 }}
 * />
 */

import React, { useState } from 'react';
import { Input, Button, Popover } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import VariableSelector from '../VariableSelector/VariableSelector';

// Styled Components
/**
 * Container wrapper for the text area and add button
 * Uses relative positioning to allow absolute positioning of the add button
 */
const Container = styled.div`
  position: relative;
  width: 100%;
`;

/**
 * Add variable button styled to match Ant Design's aesthetic
 * Positioned absolutely in the top right corner of the container
 */
const AddButton = styled(Button)`
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 1;
`;

/**
 * Custom styled textarea that includes styling for variables
 * Variables are highlighted with a distinct background and border
 */
const StyledTextArea = styled(Input.TextArea)`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial;
  padding-right: 40px;
  
  .ant-input {
    font-family: inherit;
  }
`;

/**
 * StyledMessageInput Component
 * 
 * A rich text input component that allows users to insert and display variables
 * within their message. Variables are visually distinct and can be inserted via
 * a popover selector.
 * 
 * @component
 * @param {Object} props - Component props
 * @param {string} props.value - Current value of the text input
 * @param {function} props.onChange - Callback when input value changes
 * @param {Object} props.variables - Object containing available variables grouped by category
 * @param {function} props.onVariableSelect - Callback when a variable is selected from the popover
 * @param {Object} [props.autoSize] - Configuration for textarea auto-sizing
 * @param {string} [props.placeholder] - Placeholder text for the input
 * 
 * State Management:
 * - Uses local state to control popover visibility
 * - Parent component manages the input value and variable selection
 * 
 * Related Components:
 * - VariableSelector: Child component for selecting variables
 * - StatsBotForm: Parent component that manages the overall form state
 * 
 * @example
 * // Inside a form component
 * const [message, setMessage] = useState('');
 * 
 * const handleVariableSelect = (variable) => {
 *   // Insert variable at cursor position
 *   const textArea = document.getElementById('message');
 *   const start = textArea.selectionStart;
 *   const end = textArea.selectionEnd;
 *   const newText = message.substring(0, start) + variable + message.substring(end);
 *   setMessage(newText);
 * };
 * 
 * return (
 *   <StyledMessageInput
 *     id="message"
 *     value={message}
 *     onChange={(e) => setMessage(e.target.value)}
 *     variables={dataSourceVariables}
 *     onVariableSelect={handleVariableSelect}
 *     placeholder="Enter your message here..."
 *     autoSize={{ minRows: 6, maxRows: 12 }}
 *   />
 * );
 */
const StyledDisplay = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial;
  padding: 4px 11px;
  padding-right: 40px;
  min-height: 100px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  background-color: #fff;
  white-space: pre-wrap;
  word-break: break-word;
  cursor: text;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  
  &:hover {
    border-color: #4096ff;
  }
`;

const Variable = styled.span`
  display: inline-block;
  background-color: #f5f5f5;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  padding: 2px 6px;
  margin: 0 2px;
  font-family: monospace;
  line-height: 1.5715;
`;

const HiddenTextArea = styled(Input.TextArea)`
  position: relative;
  width: 100%;
  min-height: 100px;
  background: transparent;
  z-index: 0;
`;

const StyledMessageInput = ({ value, onChange, variables, onVariableSelect, ...props }) => {
  const [visible, setVisible] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
    if (newVisible) {
      setSelectedValue(null);
    }
  };

  const handleSelect = (variable) => {
    onVariableSelect(variable);
    setVisible(false);
    setSelectedValue(null);
  };

  return (
    <Container>
      <StyledTextArea
        value={value}
        onChange={onChange}
        {...props}
      />
      <Popover
        content={
          <div style={{ width: 650 }}>
            <VariableSelector
              variables={variables}
              onSelect={handleSelect}
              value={selectedValue}
            />
          </div>
        }
        title={<div style={{ padding: '0 16px' }}>Insert Variable</div>}
        trigger="click"
        visible={visible}
        onVisibleChange={handleVisibleChange}
        placement="bottom"
        overlayInnerStyle={{ padding: 0 }}
        overlayStyle={{ 
          width: 650,
          maxHeight: '80vh',
          overflowY: 'auto'
        }}
        getPopupContainer={trigger => trigger.parentElement}
        align={{
          offset: [-300, 10]
        }}
      >
        <AddButton icon={<PlusOutlined />} />
      </Popover>
    </Container>
  );
};

export default StyledMessageInput; 